import React from 'react';
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";

function Menu({step, steps, handleNext, firstButton}) {
    const [state, setState] = React.useState({checked: false});

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
            <div>
                    <Typography variant='h6'>Herzlich Willkommen auf der Wahl-Webseite zur Volksinitiative</Typography>
                    <Typography variant='h6'>"Für verantwortungsvolle Unternehmen - Zum Schutz von Mensch und Umwelt"</Typography>
                    <h1>  </h1>
                    <h1>  </h1>
                    <h1>  </h1>
                    <h1>  </h1>
                    <Typography variant='subtitle1'><b>Auswahl der Wahlkarte</b></Typography>
                    <h1>  </h1>
                    <Typography variant='body2'><b>Entscheiden Sie sich für eine Wahlmöglichkeit</b> und legen Sie die entsprechende Wahlkarte auf dem Feld <b>WAHLKARTE HIER ABLEGEN</b> ab.</Typography>
                    <Typography variant='body2'>Legen Sie die restlichen Wahlkarten zurück in den Umschlag.</Typography>
                    <h1>  </h1>
                    <h1>  </h1>
            
                <FormControlLabel
                control={
                    <Checkbox
                        checked={state.checked}
                        onChange={handleChange}
                        name="checked"
                        color="primary"
                    />
                }
                    label="Die Wahlkarte wurde korrekt abgelegt"
                />
            <Button
                variant="contained"
                color="primary"
                    disabled={step !== steps.start}
                    onClick={handleNext}>
                {firstButton}
                </Button>
                <Box m={1}/>
        </div>
    );
}

export default Menu;