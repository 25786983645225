import React from 'react';
import Button from "@material-ui/core/Button";
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";

function StimmabgabeButton({handleNext}) {
    const [state, setState] = React.useState({checked: false});

    return (
        <div>
            <Typography variant='subtitle1'><b>Stimmabgabe</b></Typography>
            <h1>  </h1>
            <Typography variant='body2'>Klicken Sie auf "WAHLKARTE SCANNEN" um die Wahlkarte einzuscannen.</Typography>
            <h1>   </h1>
            
            <Button
                variant="contained"
                color="primary"
                onClick={handleNext}>
                Wahlkarte einscannen
            </Button>
        </div>
    );
}

export default StimmabgabeButton;