import React from 'react';
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import {Grid, Typography} from "@material-ui/core";

function SecondButton({step, steps, handleNext, firstButton}) {

    return (
        <Grid
            container
            direction="column"
            justify="space-evenly"
            alignItems="center">
                <div>
                    <Typography variant='subtitle1'><b>Bestätigung</b></Typography>
                    <h1>  </h1>
                    <h1>  </h1>
                    <Typography variant='body2'><i>Hinweis: Mit dem einscannen des Bestätigungscodes bestätigen Sie, dass Sie die Wahl <b>alleine und unbeobachtet</b> durchgeführt haben.</i></Typography>
                    <h1>  </h1>
                    <Typography variant='body2'>Klicken Sie nun auf "<b>BESTÄTIGUNGSCODE SCANNEN</b>".</Typography>
                    <h1>  </h1>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}>
                    Bestätigungscode scannen
                </Button>
                
            </Grid>
    );
}

export default SecondButton;