import React from 'react';
import Button from "@material-ui/core/Button";
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";

function FinalisationCode({handleNext}) {
    const [state, setState] = React.useState({checked: false});

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <div>
            <Typography variant='subtitle1'><b>Finalisierungscode</b></Typography>
            <h1>  </h1>
            <Typography variant='body2'>Nutzen Sie nun eine Münze, um den Finalisierungscode freizurubbeln.</Typography>
            <h1>  </h1>
            <Typography variant='body2'><b>Überprüfen</b> Sie, ob der Ihnen hier angezeigte Finalisierungscode mit dem auf Ihrem Stimmrechtsausweis <b>übereinstimmt</b>.</Typography>
            <h1>  </h1>
            <Typography variant='body2'>Wenn dieser Code <b>übereinstimmt</b>, haben Sie die Wahl abgeschlossen. </Typography>
            <h1>  </h1>
            <Typography variant='body2'>Der Finalisierungscode lautet: <b>4946-0511</b></Typography>
            <h1>   </h1>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={state.checked}
                        onChange={handleChange}
                        name="checked"
                        color="primary"
                    />
                }
                label="Der Finalisierungscode ist korrekt"
            />
            
            <Button
                variant="contained"
                color="primary"
                disabled={!state.checked}
                onClick={handleNext}>
                Weiter
            </Button>
        </div>
    );
}

export default FinalisationCode;