import React from 'react';
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import {Grid, Typography} from "@material-ui/core";

function ThirdButton({step, steps, handleNext, firstButton, surveyURL}) {

    return (
        <Grid
            container
            direction="column"
            justify="space-evenly"
            alignItems="center">
                <div>
                    <Typography variant='subtitle1'><b>Sie haben die Wahl hiermit abgeschlossen.</b></Typography>
                    <h1>   </h1>
                    <Typography variant='body2'>Klicken Sie nun auf Feedback geben, um an der Umfrage teilzunehmen.</Typography>
                    <h1>  </h1>
                    <h1>  </h1>
                    
                </div>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        var win = window.open(surveyURL, '_self');
                        win.focus();
                    }}>
                    Feedback geben
                </Button>
            </Grid>
    );
}

export default ThirdButton;