import './App.css';
import "@fontsource/roboto";
import {CssBaseline, Grid} from "@material-ui/core";
import Topnav from "./components/Topnav";
import React from "react";
import Menu from "./components/Menu";
import StimmabgabeButton from './components/StimmabgabeButton';
import VotingCode from "./components/VotingCode";
import ReturnCode from "./components/ReturnCode";
import SecondButton from './components/SecondButton';
import SingleCodeScanner from "./components/SingleCodeScanner";
import FinalisationCode from "./components/FinalisationCode";
import ThirdButton from './components/ThirdButton';
import confirmationCodeOverlay from "./assets/overlay_confirmation.png"


const codeVotingSteps = {
    start: 0,
    buttonScanVotingCode: 1,
    scanVotingCode: 2,
    returnCode: 3,
    returnCodeDone: 4,
    scanConfirmationCode: 5,
    finalisationCode: 6,
    finish: 7
}


function App() {
    const [step, setStep] = React.useState(0);
    const [returnCode, setReturnCode] = React.useState("");

    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);
    };

    return (
        <div>
            <Grid container direction="column">
                <Topnav className="Topnav"/>
                <div className="ContainerStyles">
                    {window.location.host.split() && <div>
                        {(step === codeVotingSteps.start) &&
                        <Menu step={step} steps={codeVotingSteps} handleNext={handleNext}
                              firstButton={"Weiter"} />}
                        {(step === codeVotingSteps.buttonScanVotingCode) &&
                        <StimmabgabeButton handleNext={handleNext} />}
                        {step === codeVotingSteps.scanVotingCode &&
                        <VotingCode handleNext={handleNext} setReturnCode={setReturnCode}/>}
                        {step === codeVotingSteps.returnCode &&
                        <ReturnCode handleNext={handleNext} returnCode={returnCode}/>}
                        {/* finalisierungscode*/}
                        {step === codeVotingSteps.returnCodeDone &&
                        <SecondButton handleNext={handleNext}/>}
                        {step === codeVotingSteps.scanConfirmationCode &&
                        <SingleCodeScanner handleNext={handleNext} code={"a321-m25n1-ks1p"}
                                           overlay={confirmationCodeOverlay}/>}
                        {step === codeVotingSteps.finalisationCode &&
                        <FinalisationCode handleNext={handleNext}/>}
                        {step === codeVotingSteps.finish &&
                        <ThirdButton handleNext={handleNext} surveyURL={"https://soscisurvey.de/wahl2021studie/"}/>}
                    </div>}
                </div>
            </Grid>
            <CssBaseline/>
        </div>
    );
}

export default App;
