import React from 'react';
import Button from "@material-ui/core/Button";
import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';

function ReturnCode({handleNext, returnCode}) {
    const [state, setState] = React.useState({checked: false});

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <div>
            <Typography variant='subtitle1'><b>Prüfcode</b></Typography>
            <h1>  </h1>
            <h1>  </h1>
            <Typography variant='body2'>Bitte <b>vergleichen Sie</b>, ob der Prüfcode, der Ihnen unten angezeigt wird, mit dem <b>übereinstimmt</b>, der auf der Rückseite der von Ihnen gewählten Wahlkart steht.</Typography>
            <h1>  </h1>
            <Typography variant='body2'>Bestätigen Sie auf der Wahl-Webseite, dass der Code korrekt ist. Legen Sie dann die verwendete Wahlkarte zurück zu den restlichen Wahlkarten in den Umschlag.</Typography>
            <h1>  </h1>
            <h1>  </h1>
            <h1>  </h1>
            <Typography variant='body2'>Der Prüfcode lautet: <b>{returnCode}</b></Typography>
            <h1>  </h1>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={state.checked}
                        onChange={handleChange}
                        name="checked"
                        color="primary"
                    />
                }
                label="Der Prüfcode ist korrekt"
            />

            <Button
                variant="contained"
                color="primary"
                disabled={!state.checked}
                onClick={handleNext}>
                Weiter
            </Button>

        </div>
    );
}

export default ReturnCode;